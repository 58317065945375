import React, { useState } from "react"
import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { OverviewSystem } from "../types/system";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Typography } from "@mui/material";

const AddChkMrkDialog = (props: any) => {
  const { title, children, open, setOpen, onConfirm, systemList } = props;

  const [value, setValue] = useState("");
  const [cronSettings, setcronSettings] = useState("");
  const [systemId, setsystemId] = useState("");

  const copyToClipboard = async (input:string) => {
    try {
      await navigator.clipboard.writeText(input);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <Dialog
      disableRestoreFocus
      open={open}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>
        <div>
          Title<br/>
          <input autoFocus type="input" style={{ width: '500px' }} id="inputField" required maxLength={100} onChange={(e) => { setValue(e.target.value) }} />
        </div>
        <div>
          <div id="bloc4">
            <div>
              <br></br>
              System:<br></br>
              <select onChange={(e) => { setsystemId(e.target.value) }} >
                <option value=""></option>
                {systemList.map((h: OverviewSystem) => <option key={h.systemId} value={h.systemId}>{h.title}</option>)}
              </select>
            </div>
          </div>
        </div>
        <br></br>
        Expected interval - cron format<IconButton aria-label="inputFieldCronSettings" onClick={() => window.open('https://crontab.guru/')}><OpenInNewIcon fontSize="small" /></IconButton><br></br>
        <div>
          <input type="input" style={{ width: '500px' }} id="inputFieldCronSettings" required maxLength={100} onChange={(e) => { setcronSettings(e.target.value) }} />
        </div>
        <Typography sx={{ color: 'text.secondary', fontSize: 14, fontStyle: 'italic' }} >
        <table className="addChkMrk" style={{ transform: 'translate(0px, 10px)' }}>
          <tr>
          <td style={{ width: 80}}>0 2 * * *</td> 
          <td>
          <Button onClick={() => copyToClipboard('0 2 * * *')} sx={{ minWidth: '30px' }}>
                <ContentCopyIcon sx={{ height: '15px' }} />
              </Button>
          </td>
          <td>Everyday morning at 2</td> 
          </tr>
          <tr>
          <td>30 16 * * 1</td> 
          <td>
          <Button onClick={() => copyToClipboard('30 16 * * 1')} sx={{ minWidth: '30px' }}>
                <ContentCopyIcon sx={{ height: '15px' }} />
              </Button>
          </td>
          <td>At 16:30 on Mondays</td> 
          </tr>
        </table>
        </Typography>
        <br/>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={() => { setOpen(false); }}
          color="error"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm(value, systemId, cronSettings);
          }}
          disabled={value.length === 0 || systemId === '' || cronSettings.length === 0}
          color="success"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddChkMrkDialog;


