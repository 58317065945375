import React, { useState } from "react"
import { Box } from '@mui/system'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Paper, Tooltip } from '@mui/material';


import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const StatusURLDialog = (props: any) => {
  const { title, children, open, setOpen, onConfirm, successURL, errorURL } = props;

  const [value, setValue] = useState("");
  const [copied, setCopied] = useState(false);

  const handleCopySuccessUrl = async () => {
    try {
      await navigator.clipboard.writeText(successURL);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  const handleCopyErrorUrl = async () => {
    try {
      await navigator.clipboard.writeText(errorURL);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const getTrimmedText = (source: string) => {
    if (source.length > 50) {
        return source.substring(0, 50).trimEnd() + '...';
    } else {
        return source;
    }
};

  return (
    <Dialog
      disableRestoreFocus
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="confirm-dialog">
        {title}
      </DialogTitle>

      <DialogContent>
        <div>
          <Paper sx={{ height: '100px' }} className="copy-box">
            <Box>
              SUCCESS - use url from clipboard:<br /><br />
              {getTrimmedText(successURL)}
            </Box>
            <Tooltip title="Copy to clipboard">
              <Button onClick={handleCopySuccessUrl} sx={{ minWidth: '30px' }}>
                <ContentCopyIcon sx={{ height: '20px', color: '#216C17' }} />
              </Button>
            </Tooltip>
          </Paper>
          <br></br>
          <Paper sx={{ height: '100px' }} className="copy-box-error">
            <Box>
            ERROR - use url from clipboard:<br /><br />
            {getTrimmedText(errorURL)}
            </Box>
            <Tooltip title="Copy to clipboard">
              <Button onClick={handleCopyErrorUrl} sx={{ minWidth: '30px' }}>
                <ContentCopyIcon sx={{ height: '20px', color: '#6c1749' }} />
              </Button>
            </Tooltip>
          </Paper>

        </div>
      </DialogContent>

      <DialogActions>
        {/* <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm(value);
          }}
          color="success"
        >
          Close
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default StatusURLDialog;


