import { Link, useParams, useSearchParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react"
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { getRTApi, getRTApiTxt, getRTFile, postRTApi } from "../rtapi";
import { rtapiLoginRequest } from "../authConfig";
import { useNavigate } from "react-router-dom";
import { useFetchSystemList } from "../hooks/SystemHooks"
import { InteractionStatus, EventType } from "@azure/msal-browser";
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { OverviewSystem, System, OverviewV2, WeekDayCheck, CheckDefinition } from "../types/system";
import DatePicker from "react-datepicker";
import Tooltip from '@mui/material/Tooltip';
import { CardActions } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import "react-datepicker/dist/react-datepicker.css";
import { propTypes } from "react-bootstrap/esm/Image";
import Button from "react-bootstrap/Button";
import OKCancelDialog from "./OKCancelDialog"
import AddChkMrkDialog from "./AddChkMrkDialog"
import EditChkMrkDialog from "./EditChkMrkDialog"
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import BellIcon from "@mui/icons-material/Notifications";
import { BsBattery, BsActivity, BsEmojiFrown, BsClockFill, BsClock } from "react-icons/bs";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import context from "react-bootstrap/esm/AccordionContext";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import StatusURLDialog from "./StatusURLDialog";
import { ToastContainer, toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';

interface PopoverProps {
  success: number;
  error: number;
  content: string;
  occurrences: Date[];
  weekDayCheck: WeekDayCheck[];
}








export default function MouseHoverPopover(props: PopoverProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [anchorStatusURL, setAnchorStatusURL] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);


  //
  const handlePopoverOpen_StatusURL = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorStatusURL(event.currentTarget);
  };

  const handlePopoverClose_StatusURL = () => {
    setAnchorStatusURL(null);
  };

  const open_StatusURL = Boolean(anchorStatusURL);
  //

  const styles = { listStyle: 'none', }

  let popupHeader = "Coming up";

  let success = props.success;
  let error = props.error;

  let title = "coming up";
  let mycontent = props.content;


  let occurrenceList: JSX.Element[] = props.occurrences.map((occurrence, index) => (
    <li style={styles} key={index}>Kl. {new Date(occurrence).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</li>
  ));

  const checksList: JSX.Element[] = props.weekDayCheck.map((weekDayChk, index) => (
    <li style={styles} key={index}>  {weekDayChk.checkDB.checkStatusId == 10 || weekDayChk.checkDB.checkStatusId == 30 ? "OK" : "ERROR"}: {new Date(weekDayChk.startDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</li>
  ));

  let past = false;

  if (props.occurrences.length > 0) {
    const now = new Date();
    const next = new Date(props.occurrences[0]);

    if (next < now) {
      title = "Past";
      occurrenceList = [];
      past = true;
    }
    else {
      let locale = "en-uk";
      popupHeader += " " + next.toLocaleDateString(locale, { weekday: 'long' }).toLowerCase();
    }

    mycontent = "Kl " + new Date(props.occurrences[0]).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  if (props.occurrences.length <= 0) {
    title = "";
    popupHeader = "";
  }

  if (props.weekDayCheck.length > 0) {
    mycontent = "Checks 2";
    title = "";
    popupHeader = "";
  }

  if (past == true && props.weekDayCheck.length <= 0 && props.occurrences.length > 0) {
    popupHeader = "Missed checks";
    title = "";
    success = -1;
    error = -1;
  }

  return (
    <div>
      {/* //{past == true && success == -1 && error == -1 && props.weekDayCheck.length <= 0 && props.occurrences.length > 0 ? */}
      {past == true && props.weekDayCheck.length <= 0 && props.occurrences.length > 0 ?
        <Stack direction="row" justifyContent="left">
          <Badge badgeContent="missed" color="error" anchorOrigin={{ vertical: "top", horizontal: "left" }}></Badge>
        </Stack>
        :
        <Typography
          sx={{ color: 'text.secondary', fontSize: 12, fontStyle: 'italic' }}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          style={{ transform: 'translate(0px, 8px)' }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {title}
          <Stack direction="row" justifyContent="left">
            <div>
              {success > 0 ?
                <Badge badgeContent={success} color="success" style={{ transform: 'translate(0px, -8px)' }} anchorOrigin={{ vertical: "top", horizontal: "left" }}>
                </Badge>
                : null
              }
            </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div>

              {error > 0 ?
                <Badge badgeContent={error} color="error" style={{ transform: 'translate(0px, -8px)' }} anchorOrigin={{ vertical: "top", horizontal: "right" }}>

                </Badge>
                : null
              }
            </div>
          </Stack>
        </Typography>
      }

      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >

        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
              {popupHeader}
            </Typography>
            <div>
              <ul>
                {checksList}
              </ul>
              <ul>
                {occurrenceList}
              </ul>
            </div>
          </CardContent>
          {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
        </Card>

      </Popover>
    </div>
  );
}

// Get the monday date of current week
function getMondayOfCurrentWeek(d: Date) {
  const date = new Date(d);
  const day = date.getDay(); // Sunday - Saturday : 0 - 6
  //  Day of month - day of week (-6 if Sunday), otherwise +1
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return date.setDate(diff), date;
}

export const CheckMark = () => {
  const { tenantid, viewid, systemid } = useParams();
  console.log('SystemOverviewV2 begin - systemid:' + systemid);

  var calcFromDate = new Date();
  calcFromDate = getMondayOfCurrentWeek(calcFromDate);
  console.log('calcFromDate: ' + calcFromDate);

  // get sunday date of current week
  var calcToDate = new Date();
  calcToDate.setDate(calcFromDate.getDate() + (7 - calcFromDate.getDay()));
  console.log('calcToDate: ' + calcToDate);

  const nav = useNavigate();

  const { instance, accounts, inProgress } = useMsal();

  const isAuthenticated = useIsAuthenticated();


  const account = useAccount(accounts[0] || {});
  const [apiData, setApiData] = useState<any[]>([]);
  const [viewData, setViewData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(calcFromDate);
  const [endDate, setEndDate] = useState(calcToDate);
  const [weekNo, setweekNo] = useState('Week ' + getWeekNumber(calcFromDate));
  const [detailDate, setDetailDate] = useState('');
  const [setupInstructionsOpen, setsetupInstructionsOpen] = useState(false);
  const [addChkMrkOpen, setaddChkMrkOpen] = useState(false);
  const [editChkMrkOpen, seteditChkMrkOpen] = useState(false);
  const [editChkMrkSelectedItem, seteditChkMrkSelectedItem] = useState<CheckDefinition | null>(null);
  

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const editChkMrkSelectedItem_handleClose = () => { 
    seteditChkMrkSelectedItem(null); 
  }; 

  console.log('startDate: ' + startDate);

  const routeChange = (tenantid: any, viewid: any, systemid: any, date: any) => {
    let path = `/systemdetail/${tenantid}/${viewid}/${systemid}/${date}`;

    nav(path, { replace: true });
  }



  const handleSystemChange = (e: any) => {

    // console.log(e.target.value);
    // debugger;

    e.preventDefault();

    nav(`/systemoverviewv2/${tenantid}/${viewid}/${e.target.value}`);

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: rtapiLoginRequest.scopes
    }

    var fromDateTxt = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
    var endDateTxt = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();

    instance.acquireTokenSilent(tokenRequest).then((response) => {
      const url2 = "v2/portal/overviewv2?tenantid=" + tenantid + "&systemid=" + e.target.value + "&viewid=" + viewid + "&fromdate=" + fromDateTxt + "&todate=" + endDateTxt;
      getRTApi(url2, response.accessToken).then((data2) => {
        setLoading(false);
        setApiData(data2);
      })
    }).catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      throw e;
    });
  }

  function getWeekNumber(date: Date): number {
    // Copying date so the original date won't be modified
    const tempDate = new Date(date.valueOf());

    // ISO week date weeks start on Monday, so correct the day number
    const dayNum = (date.getDay() + 6) % 7;

    // Set the target to the nearest Thursday (current date + 4 - current day number)
    tempDate.setDate(tempDate.getDate() - dayNum + 3);

    // ISO 8601 week number of the year for this date
    const firstThursday = tempDate.valueOf();

    // Set the target to the first day of the year
    // First set the target to January 1st
    tempDate.setMonth(0, 1);

    // If this is not a Thursday, set the target to the next Thursday
    if (tempDate.getDay() !== 4) {
      tempDate.setMonth(0, 1 + ((4 - tempDate.getDay()) + 7) % 7);
    }

    // The weeknumber is the number of weeks between the first Thursday of the year
    // and the Thursday in the target week
    return 1 + Math.ceil((firstThursday - tempDate.valueOf()) / 604800000); // 604800000 = number of milliseconds in a week
  }


  const handleLeftArrowDateChange = () => {

    var calcFromDate = startDate;

    calcFromDate.setDate(calcFromDate.getDate() - (8 - calcFromDate.getDay()));
    setStartDate(calcFromDate);

    setweekNo('Week ' + getWeekNumber(calcFromDate));

    var calcEndDate = new Date(calcFromDate.getFullYear(), calcFromDate.getMonth(), calcFromDate.getDate() + 6);
    setEndDate(calcEndDate);

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: rtapiLoginRequest.scopes
    }

    var dateTxt = calcFromDate.getFullYear() + '-' + (calcFromDate.getMonth() + 1) + '-' + calcFromDate.getDate();
    var endDateTxt = calcEndDate.getFullYear() + '-' + (calcEndDate.getMonth() + 1) + '-' + calcEndDate.getDate();

    let url2 = "";
    if (systemid === undefined)
      url2 = "v2/portal/overviewv2?tenantid=" + tenantid + "&systemid=&viewid=" + viewid + "&fromdate=" + dateTxt + "&todate=" + endDateTxt;
    else
      url2 = "v2/portal/overviewv2?tenantid=" + tenantid + "&systemid=" + systemid + "&viewid=" + viewid + "&fromdate=" + dateTxt + "&todate=" + endDateTxt;

    instance.acquireTokenSilent(tokenRequest).then((response) => {

      getRTApi(url2, response.accessToken).then((data2) => {
        setLoading(false);
        setApiData(data2);
      })
    }).catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      throw e;
    });
  }

  const handleRightArrowDateChange = () => {

    var calcFromDate = startDate;
    calcFromDate.setDate(calcFromDate.getDate() + (8 - calcFromDate.getDay()));
    setStartDate(calcFromDate);

    setweekNo('Week ' + getWeekNumber(calcFromDate));


    var calcEndDate = new Date(calcFromDate.getFullYear(), calcFromDate.getMonth(), calcFromDate.getDate() + 6);
    setEndDate(calcEndDate);

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: rtapiLoginRequest.scopes
    }

    var dateTxt = calcFromDate.getFullYear() + '-' + (calcFromDate.getMonth() + 1) + '-' + calcFromDate.getDate();
    var endDateTxt = calcEndDate.getFullYear() + '-' + (calcEndDate.getMonth() + 1) + '-' + calcEndDate.getDate();
    //    const url2 = "v2/portal/overview?tenantid=" + tenantid + "&systemid=" + systemid + "&viewid=" + viewid + "&fromdate=" + dateTxt;

    let url2 = "";
    if (systemid === undefined)
      url2 = "v2/portal/overviewv2?tenantid=" + tenantid + "&systemid=&viewid=" + viewid + "&fromdate=" + dateTxt + "&todate=" + endDateTxt;
    else
      url2 = "v2/portal/overviewv2?tenantid=" + tenantid + "&systemid=" + systemid + "&viewid=" + viewid + "&fromdate=" + dateTxt + "&todate=" + endDateTxt;

    instance.acquireTokenSilent(tokenRequest).then((response) => {

      getRTApi(url2, response.accessToken).then((data2) => {
        setLoading(false);
        setApiData(data2);
      })
    }).catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      throw e;
    });
  }


  const handleFromDateChange = (e: any) => {

    //debugger;

    var date = new Date(e);
    setStartDate(date);

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: rtapiLoginRequest.scopes
    }
    // console.log('endDate: ' + endDate);

    var dateTxt = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    var endDateTxt = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
    //    const url2 = "v2/portal/overview?tenantid=" + tenantid + "&systemid=" + systemid + "&viewid=" + viewid + "&fromdate=" + dateTxt;

    let url2 = "";
    if (systemid === undefined)
      url2 = "v2/portal/overviewv2?tenantid=" + tenantid + "&systemid=&viewid=" + viewid + "&fromdate=" + dateTxt + "&todate=" + endDateTxt;
    else
      url2 = "v2/portal/overviewv2?tenantid=" + tenantid + "&systemid=" + systemid + "&viewid=" + viewid + "&fromdate=" + dateTxt + "&todate=" + endDateTxt;

    instance.acquireTokenSilent(tokenRequest).then((response) => {

      getRTApi(url2, response.accessToken).then((data2) => {
        setLoading(false);
        setApiData(data2);
      })
    }).catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      throw e;
    });
  }

  const handleToDateChange = (e: any) => {

    //debugger;

    var date = new Date(e);
    setEndDate(date);

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: rtapiLoginRequest.scopes
    }

    var fromDateTxt = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
    var endDateTxt = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    //    const url2 = "v2/portal/overview?systemid=" + systemid + "&viewid=" + viewid + "&fromdate=" + fromDateTxt + "&todate=" + endDateTxt;

    let url2 = "";
    if (systemid === undefined)
      url2 = "v2/portal/overviewv2?systemid=&viewid=" + viewid + "&fromdate=" + fromDateTxt + "&todate=" + endDateTxt + "&tenantid=" + tenantid;
    else
      url2 = "v2/portal/overviewv2?systemid=" + systemid + "&viewid=" + viewid + "&fromdate=" + fromDateTxt + "&todate=" + endDateTxt + "&tenantid=" + tenantid;


    instance.acquireTokenSilent(tokenRequest).then((response) => {

      getRTApi(url2, response.accessToken).then((data2) => {
        setLoading(false);
        setApiData(data2);
      })
    }).catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      throw e;
    });
  }

  const styleObject = {
    "font-size": "11px",
  }

  const handleClick = (e: any) => {

    //debugger;

    e.preventDefault();

    let path = `/systemdetail/${viewid}/${systemid}/${detailDate}`;

    console.log(path);

    nav(path, { replace: true });


    console.log('The link was clicked.');
  }

  const sleep = (ms: any) => new Promise(r => setTimeout(r, ms));
  const toast_ChkMrkAdded = () => toast("Checkmark added...");
  const toast_ChkMrkEdited = () => toast("Checkmark edited...");

  const addCheckMark = (title: string, systemId: string, cronSettings: string) => {

    if (title == '') return;
    if (systemId == '') return;
    if (cronSettings == '') return;

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: rtapiLoginRequest.scopes
    }

    let bodyTxt = JSON.stringify({ tenantId: tenantid, title: title, systemId: systemId, cronSettings: cronSettings });

    // Acquire an access token
    instance.acquireTokenSilent(tokenRequest).then((response) => {
      const url = "v2/checks/createNewChkMrk";

      postRTApi(url, response.accessToken, bodyTxt).then((data) => {

        toast_ChkMrkAdded();

        sleep(2000).then(() => {
          window.location.reload();
        });


      });
    }).catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      throw e;
    });
  }


  const editCheckMark = (checkDefinitionId: string, title: string, cronSettings: string) => {
    
    seteditChkMrkSelectedItem(null);

    if (checkDefinitionId == '') return;
    if (title == '') return;
    if (cronSettings == '') return;

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: rtapiLoginRequest.scopes
    }

    let bodyTxt = JSON.stringify({ tenantId: tenantid, title: title, checkDefinitionId: checkDefinitionId, cronSettings: cronSettings });

    // Acquire an access token
    instance.acquireTokenSilent(tokenRequest).then((response) => {
      const url = "v2/checks/updateChkMrk";

      postRTApi(url, response.accessToken, bodyTxt).then((data) => {

        window.location.reload();


      });
    }).catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      throw e;
    });
  }


  useEffect(() => {

    const callbackId = instance.addEventCallback((message: { eventType: EventType; payload: any; }) => {
      // This will be run every time an event is emitted after registering this callback
      // console.log('xy4711: ' + message.eventType);
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const result = message.payload;
        window.location.reload();
        // Do something with the result
      }
    });

    // console.log('SYSTEMOverview a, loading: ' + loading + ' apiData.length: ' + apiData.length + ' viewid: ' + viewid + ' inProgress: ' + inProgress + ' accounts.length: ' + accounts.length + ' isAuthenticated: ' + isAuthenticated);

    if (!loading && (inProgress === InteractionStatus.None || inProgress === InteractionStatus.Startup) && accounts.length > 0) {

      // console.log('SYSTEMOverview b, loading: ' + loading + ' apiData.length: ' + apiData.length + ' viewid: ' + viewid + ' inProgress: ' + inProgress + ' accounts.length: ' + accounts.length + ' isAuthenticated: ' + isAuthenticated);

      if (apiData && apiData.length > 0) {

        // console.log('SYSTEMOverview c, loading: ' + loading + ' apiData.length: ' + apiData.length + ' viewid: ' + viewid + ' inProgress: ' + inProgress + ' accounts.length: ' + accounts.length + ' isAuthenticated: ' + isAuthenticated);

        // Skip data refresh if already set - adjust logic for your specific use case
        // console.log('skipping here');
        return;
      }

      const tokenRequest = {
        account: accounts[0], // This is an example - Select account based on your app's requirements
        scopes: rtapiLoginRequest.scopes
      }

      // Acquire an access token
      instance.acquireTokenSilent(tokenRequest).then((response) => {

        // console.log('SYSTEMOverview d, loading: ' + loading + ' apiData.length: ' + apiData.length + ' viewid: ' + viewid + ' inProgress: ' + inProgress);

        const url = "v2/portal/viewsystems?tenantid=" + tenantid + "&viewid=" + viewid;
        getRTApi(url, response.accessToken).then((data) => {

          console.log('should we load view data here???');
          if (systemid === undefined) {
            console.log('should we load view data here  AAA');
            setViewData(data);
          }
          else {
            console.log('should we load view data here  BBB');
            setViewData(data);
          }


        }).then(() => {

          var fromDateTxt = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
          var endDateTxt = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();

          let url2 = "";
          if (systemid === undefined)
            url2 = "v2/portal/overviewv2?tenantid=" + tenantid + "&systemid=&viewid=" + viewid + "&fromdate=" + fromDateTxt + "&todate=" + endDateTxt;
          else
            url2 = "v2/portal/overviewv2?tenantid=" + tenantid + "&systemid=" + systemid + "&viewid=" + viewid + "&fromdate=" + fromDateTxt + "&todate=" + endDateTxt;

          console.log('URL2' + url2);

          getRTApi(url2, response.accessToken).then((data2) => {
            // console.log('data2 B length'  + data2.length);
            setApiData(data2);
            setLoading(false);
          })
        }
        );
      }).catch(async (e) => {
        // Catch interaction_required errors and call interactive method to resolve
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(tokenRequest);
        }

        throw e;
      });
    }
  }, []);


  return (
    <div>
      <div className="divcontainerFlexEnd">
        <IconButton onClick={() => setaddChkMrkOpen(true)}><AddCircleOutlineIcon color="primary" /></IconButton>
        <AddChkMrkDialog
          title="Create checkmark"
          systemList={viewData}
          open={addChkMrkOpen}
          setOpen={setaddChkMrkOpen}
          onConfirm={addCheckMark}
        >
        </AddChkMrkDialog>
      </div>
      <div><h4 style={{ display: "inline" }}>Checkmarks</h4> <Tooltip title="Jump to current week"><p onClick={() => window.location.reload()} style={{ display: "inline" }}><HomeIcon color="primary" style={{ transform: 'translate(10px, -3px)' }} /></p></Tooltip></div>
      <div className="row mb-2" style={{ paddingTop: "10px" }}>
        <div id="blocspace"></div>
        <div id="blocspace"></div>
        <div id="block_container">
          <Tooltip title="Previous week">
            <div onClick={handleLeftArrowDateChange} className="ArrowLink">
              <ArrowBackIcon color="primary" />
            </div>
          </Tooltip>

          <div id="blocspace"></div>
          <Tooltip title="Next week">
            <div onClick={handleRightArrowDateChange} className="ArrowLink">
              <ArrowForwardIcon color="primary" />
            </div>
          </Tooltip>
          <div id="blocspace"></div>
          <div id="blocspace"></div>
          <div id="blocspace"></div>
          {startDate.toLocaleDateString("da-DK")} .. {endDate.toLocaleDateString("da-DK")} ({weekNo})
          <div id="blocspace"></div>
          <div id="blocspace"></div>
        </div>
      </div>
      <table className="checkv2" id="tableOverView">
        <tbody>
          <tr>
            <th>
            </th>
            <th style={{ textAlign: "center" }}>
              Monday
            </th>
            <th style={{ textAlign: "center" }}>
              Tuesday
            </th>
            <th style={{ textAlign: "center" }}>
              Wednesday
            </th>
            <th style={{ textAlign: "center" }}>
              Thursday
            </th>
            <th style={{ textAlign: "center" }}>
              Friday
            </th>
            <th style={{ textAlign: "center" }}>
              Saturday
            </th>
            <th style={{ textAlign: "center" }}>
              Sunday
            </th>
          </tr>

          {apiData &&
            apiData.map((h: OverviewV2) => (
              <tr key={h.checkDefinition.checkDefinitionId}>
                <td className="DateClassNoMinWidth">
                  <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography><span style={{ fontSize: "12px" }}>{h.systemName} - {h.checkDefinition.title}</span></Typography>
                    <Typography><EditIcon color="action" fontSize="small" onClick={() => { seteditChkMrkSelectedItem(h.checkDefinition); seteditChkMrkOpen(true);   } } />
                      { editChkMrkSelectedItem &&
                      (
                      <EditChkMrkDialog
                        title="Edit checkmark"
                        open={editChkMrkOpen}
                        setOpen={seteditChkMrkOpen}
                        onConfirm={editCheckMark}
                        currentCheckDefinition={editChkMrkSelectedItem}
                      >
                      </EditChkMrkDialog>
                      )}
                      &nbsp;&nbsp;
                      <InfoIcon color="action" fontSize="small" onClick={() => setsetupInstructionsOpen(true)} />
                      <StatusURLDialog
                        title="Setup instructions"
                        open={setupInstructionsOpen}
                        setOpen={setsetupInstructionsOpen}
                        successURL={h.checkDefinition.statusURLSubmitOK}
                        errorURL={h.checkDefinition.statusURLSubmitError}
                      >
                      </StatusURLDialog>
                    </Typography>

                  </Grid>
                </td>
                <td>
                  <div>
                    <Stack direction="row" justifyContent="center">
                      <MouseHoverPopover content={h.mondayOccurrences.toString()} occurrences={h.mondayOccurrences} weekDayCheck={h.weekDayCheckList.Monday} success={h.mondaySuccess} error={h.mondayError} />
                    </Stack>
                  </div>
                </td>
                <td>
                  <Stack direction="row" justifyContent="center">
                    <div>
                      <MouseHoverPopover content={h.tuesdayOccurrences.toString()} occurrences={h.tuesdayOccurrences} weekDayCheck={h.weekDayCheckList.Tuesday} success={h.tuesdaySuccess} error={h.tuesdayError} />
                    </div>
                  </Stack>
                </td>
                <td>
                  <div>
                    <Stack direction="row" justifyContent="center">
                      <MouseHoverPopover content={h.wednesdayOccurrences.toString()} occurrences={h.wednesdayOccurrences} weekDayCheck={h.weekDayCheckList.Wednesday} success={h.wednesdaySuccess} error={h.wednesdayError} />
                    </Stack>
                  </div>
                </td>
                <td>
                  <Stack direction="row" justifyContent="center">
                    <div>
                      <MouseHoverPopover content={h.thursdayOccurrences.toString()} occurrences={h.thursdayOccurrences} weekDayCheck={h.weekDayCheckList.Thursday} success={h.thursdaySuccess} error={h.thursdayError} />
                    </div>
                  </Stack>
                </td>
                <td>
                  <Stack direction="row" justifyContent="center">
                    <div>
                      <MouseHoverPopover content={h.fridayOccurrences.toString()} occurrences={h.fridayOccurrences} weekDayCheck={h.weekDayCheckList.Friday} success={h.fridaySuccess} error={h.fridayError} />
                    </div>
                  </Stack>
                </td>
                <td>
                  <Stack direction="row" justifyContent="center">
                    <div>
                      <MouseHoverPopover content={h.saturdayOccurrences.toString()} occurrences={h.saturdayOccurrences} weekDayCheck={h.weekDayCheckList.Saturday} success={h.saturdaySuccess} error={h.saturdayError} />
                    </div>
                  </Stack>
                </td>
                <td>
                  <Stack direction="row" justifyContent="center">
                    <div>
                      <MouseHoverPopover content={h.sundayOccurrences.toString()} occurrences={h.sundayOccurrences} weekDayCheck={h.weekDayCheckList.Sunday} success={h.sundaySuccess} error={h.sundayError} />
                    </div>
                  </Stack>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

    </div>
  );
};



