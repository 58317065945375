import React, { useState } from "react"
import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const OKCancelDialog = (props: any) => {
  const { title, children, open, setOpen, onConfirm } = props;
  
  const[value, setValue] = useState(""); 

  return (
    <Dialog
      disableRestoreFocus
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}
          <div>
          <input autoFocus type="input"  style={{width: '500px'}} id="inputField" required maxLength={300} onChange={(e) => {setValue(e.target.value)}} />
          </div> 
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color="error"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm(value);
          }}
          disabled={value.length===0}
          color="success"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OKCancelDialog;


