import React, { useState, useRef, useEffect } from "react"
import { Box } from '@mui/system'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Paper, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CheckDefinition } from "../types/system";

// https://www.quora.com/In-React-or-Next-js-when-you-iterate-over-an-array-using-the-map-function-and-return-some-MUI-buttons-and-dialogues-how-do-you-display-a-dialog-only-when-you-click-on-one-of-the-buttons

const EditChkMrkDialog = (props: any) => {
  const { open, setOpen, onConfirm, currentCheckDefinition } = props;

  const [value, setValue] = useState((currentCheckDefinition as CheckDefinition).title);
  const [cronSettings, setcronSettings] = useState((currentCheckDefinition as CheckDefinition).cronSettings);
  
  return (
    <Dialog
      disableRestoreFocus
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="confirm-dialog">
        Edit checkmark
      </DialogTitle>

      <DialogContent>
        <div>
          <input autoFocus defaultValue={(currentCheckDefinition as CheckDefinition).title} type="input" style={{ width: '500px' }} id="inputField" required maxLength={100} onChange={(e) => { setValue(e.target.value) }} />
        </div>
        <br></br>
        Expected interval - cron format<IconButton aria-label="inputFieldCronSettings" onClick={() => window.open('https://crontab.guru/')}><OpenInNewIcon fontSize="small" /></IconButton><br></br>
        <div>
          <input defaultValue={(currentCheckDefinition as CheckDefinition).cronSettings} type="input" style={{ width: '500px' }} id="inputFieldCronSettings" required maxLength={100} onChange={(e) => { setcronSettings(e.target.value) }} />
        </div>

      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={() => { setOpen(false); }}
          color="error"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm((currentCheckDefinition as CheckDefinition).checkDefinitionId, value, cronSettings);
          }}
          disabled={value.length === 0 || cronSettings.length === 0}
          color="success"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditChkMrkDialog;


